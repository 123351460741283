import ajaxGetFamiliarJobs from './dataSource'
import withSlidableJobPopulation from '../HOCs/withSlidableJobList'
import { TJobBlock } from '../JobBlock/type'
import { memo } from 'react'
import dynamic from 'next/dynamic'

const JobBlock = dynamic(() => import('../JobBlock'))

const RecoJobsSection = withSlidableJobPopulation<TJobBlock>(
	JobBlock,
	ajaxGetFamiliarJobs,
	true
)

export default memo(RecoJobsSection)
