import React, { memo } from 'react'
import ClampLines from 'react-clamp-lines'
import { assetUrl } from '../../helpers/urlBuilder'
import Image from 'next/legacy/image'

type TCardBlockProps = {
	image: string
	title: string
	category: string
	content: string
	url: string
}

const isMobile = typeof window !== 'undefined' ? window.innerWidth < 768 : false

const CardBlock = (props: TCardBlockProps) => {
	const imageUrl = props.image
		? props.image
		: assetUrl('/static/images/advice_placeholder.jpg')
	return (
		<div className='cardBlock'>
			<a href={props.url} title={props.title} target='_blank'>
				<figure className='cardBlock__image'>
					<Image
						src={imageUrl}
						alt={props.title}
						width={384}
						height={200}
					/>
				</figure>
			</a>
			<div className='cardBlock__content-wrapper'>
				<div className='cardBlock__title'>
					<a
						href={props.url}
						title={props.title}
						className='text-like'
						target='_blank'
					>
						<ClampLines
							id={'card-title'}
							text={props.title}
							lines={3}
							buttons={false}
						/>
					</a>
				</div>
				<div className='cardBlock__content' title={props.content}>
					<ClampLines
						id={'card-content'}
						text={props.content}
						lines={isMobile ? 2 : 4}
						buttons={false}
					/>
				</div>
				<div className='cardBlock__category ellipsis'>
					{props.category != '' && (
						<span className='cardBlock__point'></span>
					)}
					<span>{props.category}</span>
				</div>
			</div>
		</div>
	)
}

export default memo(CardBlock)
