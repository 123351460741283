import { makeRequest } from '../../services/apiClient'
import { getIdFromCode } from '../../helpers/language'
import { buildQueryString } from '../../helpers/urlBuilder'
import { utmRecoJob } from '../../helpers/utmTracking'
import { ELanguage, IJob } from '@microfrontends/react-components'

const apiUrl = `${process.env.PREDICTION_IO_API_BASE}/v2/jobs`
const baseApiUrl = process.env.API_DOMAIN

function buildUrl(jobId: number, userId: number) {
	const queryString = buildQueryString({
		jobId: jobId,
		user: userId,
		limit: 80,
		t: String(Date.now())
	})

	return `${apiUrl}?${queryString}`
}

export default async function dataSource(
	jobId: number,
	userId: number,
	langCode: ELanguage,
	accessToken: string
) {
	const langId = getIdFromCode(langCode)
	const data = await makeRequest(
		buildUrl(jobId, userId),
		'get',
		null,
		langCode
	)

	if (!!data.jobs) {
		const trackingSource = data.source
		const utmTracking = utmRecoJob(trackingSource)
		const resData = data.jobs.map((item: IJob) => {
			const customUrl = `${process.env.VNW_BASE_URL}/${item.alias}-${
				item.jobId
			}-${langCode === ELanguage.EN ? 'jd' : 'jv'}/?${utmTracking}`
			return {
				title: item.jobTitle,
				locations: item.locations?.[langId],
				company: item.company,
				logo: item.companyLogo,
				url: customUrl,
				jobId: item.jobId,
				companyId: item.companyId,
				salary: item.prettySalary,
				jobLevel:
					langCode === ELanguage.VI ? item.jobLevelVI : item.jobLevel,
				jobLevelId: item.jobLevelId,
				address: item.address,
				jobDescription: item.jobDescription,
				jobRequirement: item.jobRequirement,
				isAnonymous: item.isAnonymous
			}
		})

		if (resData.length > 0 && accessToken) {
			const jobIds = resData.map((item: { jobId: number }) => item.jobId)

			const resSpecialJobs = await fetch(
				`${baseApiUrl}/api-gateway/v1.0/special-jobs`,
				{
					method: 'POST',
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`
					},
					body: JSON.stringify({
						jobId: jobIds
					})
				}
			)

			const specialJobs = await resSpecialJobs.json()
			const convertData = resData.map((item: any, idx: number) => {
				return {
					...item,
					...specialJobs.data[idx].attributes
				}
			})

			return convertData
		}

		return resData
	}

	return []
}
