import Link from 'next/link'
import { useContext } from 'react'
import AppContext from '../../context/AppContext'

interface IProps {
	href: string
}

export default function ViewAllBtn({ href }: IProps) {
	const { t } = useContext(AppContext)
	return (
		<Link href={href} passHref>
			<span>{t('View All')}</span>
		</Link>
	)
}
