export const META_CITY_CACHE_KEY = 'metaCity'
export const FEATURE_JOBS_CACHE_KEY = 'featuredJobs'
export const HERO_BANNERS_CACHE_KEY = 'heroBanners'
export const FEATURED_COMPANIES_CACHE_KEY = 'featuredCompanies'
export const HOT_CATEGORIES_CACHE_KEY = 'hotCategories'
export const JOB_CORNER_CACHE_KEY = 'jobCorner'
export const COMPANIES_SPOTLIGHT_CACHE_KEY = 'spotlight'
export const HR_INSIDER_CACHE_KEY = 'hrInsider'
export const PODCAST_CACHE_KEY = 'podcast'
export const VNW_JS_TOKEN_KEY = 'VNW128450527232960'
export const VNW_WS_TOKEN_KEY = 'VNWWS128450527232960'

export const CACHE_LIFE_TIME = process.env.CACHE_LIFE_TIME ?? 1800
