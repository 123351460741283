/**
 * VNW-23152 [Web][NaviCMS] Update podcast
 *
 * @author HieuNPM
 * @since Jul 10 2023
 */

import { ELanguage } from '@microfrontends/react-components'
import { makeRequest } from '../../services/apiClient'

const API_PATH = process.env.PODCAST_API

export interface IPodcast {
	title: string
	time: string
	link: string
	url?: string
	utm?: string
	image: string
}

export default async function dataSource(
	langCode: ELanguage
): Promise<IPodcast | undefined> {
	try {
		let data = await makeRequest(`${API_PATH}`)
		if (!Array.isArray(data) || data.length === 0) {
			return
		}

		let item = data.pop()
		let date = new Date(item.publishedOn)

		// 'Apr 7' || '7 tháng 4'
		let displayTime =
			langCode === ELanguage.EN
				? `${date.toLocaleString('en', {
						month: 'short'
				  })} ${date.getDate()}`
				: `${date.getDate()} ${date.toLocaleString('vi', {
						month: 'long'
				  })}`

		return {
			title: item.title,
			time: displayTime,
			link: item.source,
			url: item.url,
			utm: item.utm,
			image: item.thumbnail
		}
	} catch (err) {
		// TODO log sentry no podcast on server ?
		return
	}
}
