import dynamic from 'next/dynamic'
import { memo, useContext } from 'react'
import AppContext from '../../../context/AppContext'
import { isMobile } from '../../../helpers'
import { EMode } from '@microfrontends/app-shell-v2'
import { TSSRResultData } from '../../../ssr/home'
import { TNestedGenericId } from '@microfrontends/react-components'
import CompanySpotlight from '../../Banners/CompanySpotlight'
import HotCategories from '../../Banners/HotCategories'
import BestJobsInJobFunction from '../../BestJobsInJobFunction'
import PodcastAudio from '../../PodcastAudio'
import WowCVSection from '../../WowCVSection'
import FeaturedJobs from '../../FeaturedJobs'
import PromotionJobs from '../../PromotionJobs'
import ViewAllBtn from '../../ViewAllButton/ViewAllBtn'
import HRInsider from '../../HRInsider'
import IntegratedJobCorner from '../../Banners/JobCorner'
import RecoJobsSection from '../../RecommendationJobs'

import LazyLoad from 'react-lazyload'

const Banner = dynamic(() => import('../../NewBanner'))
const CompanyList = dynamic(() => import('../../Banners/FeaturedCompany'))

interface IProps {
	isRobotRequesting: boolean
	viewport: 'mobile' | 'desktop'
	resultAll: Partial<TSSRResultData>
}

function HomePage({
	isRobotRequesting: isBot,
	resultAll,
	viewport
}: Partial<IProps>) {
	const { langCode, serviceWorker, user, userIp, t, locations } =
		useContext(AppContext)
	const jobFunction = user?.jobFunction || []
	const isShowBestJobs = user && user.userId && jobFunction?.length > 0

	const renderBestJobsInJobFunction = () => {
		if (!isShowBestJobs) return null
		const elements = jobFunction?.map(
			(jobFunctionItem: TNestedGenericId, cateIndex) => {
				return (
					<BestJobsInJobFunction
						key={`${cateIndex}-${jobFunctionItem.parentId}`}
						jobFunctionItem={jobFunctionItem}
						locationId={locations[0]?.parentId ?? 0}
						langCode={langCode}
						user={user}
					/>
				)
			}
		)
		return elements
	}

	return (
		<div id='pageContentWrapper'>
			<Banner
				detectedLocation={locations}
				serviceWorker={serviceWorker}
				language={langCode}
				user={user}
				userIP={userIp}
				translate={t}
				banners={resultAll?.banners}
				isMobile={viewport === 'mobile' ? true : isMobile()}
				t={t}
				mode={process.env.APP_SHELL_MODE as EMode}
			/>
			<CompanyList
				langCode={langCode}
				companies={resultAll?.companies ?? []}
			/>
			<LazyLoad height={431} offset={isMobile() ? -100 : 100} once={true}>
				<FeaturedJobs
					jobs={resultAll?.featuredJobs}
					sectionTitle={t('Featured Jobs')}
					slideWrapperClass={['featuredJobs']}
					langCode={langCode}
					ctaLink={<ViewAllBtn href={t('/featured-jobs')} />}
					user={user}
					isBot={isBot ?? false}
				/>
			</LazyLoad>
			<LazyLoad height={570} offset={100} once={true}>
				<HotCategories
					categories={resultAll?.hotCategories?.categories}
					langCode={langCode}
					isRobot={isBot ?? false}
				/>
			</LazyLoad>
			{/* Exclude RecoJobsSection block because user and jobId are required */}
			<LazyLoad height={502.5} offset={100} once={true}>
				<IntegratedJobCorner {...resultAll?.jobCorner} />
			</LazyLoad>
			<LazyLoad height={376} offset={100} once={true}>
				<RecoJobsSection
					sectionTitle={t('Recommendation Jobs')}
					slideWrapperClass={['recoJobs']}
					noDots={true}
					user={user}
					userIP={userIp}
					langCode={langCode}
					accessToken={user?.accessToken ?? ''}
					ctaLink={<ViewAllBtn href={t('/recommended-jobs')} />}
				/>
			</LazyLoad>
			<LazyLoad height={570} offset={100} once={true}>
				<CompanySpotlight
					company={resultAll?.spotlight}
					langCode={langCode}
					isBot={isBot ?? false}
				/>
			</LazyLoad>
			{/* Exclude BestJobsInCategoryComponent block because user is required */}
			<LazyLoad height={431} offset={isMobile() ? -100 : 100} once={true}>
				{renderBestJobsInJobFunction()}
			</LazyLoad>
			{/* Exclude PromotionJobs block because not params query */}
			<LazyLoad height={452} offset={100} once={true}>
				<PromotionJobs />
			</LazyLoad>
			<LazyLoad height={452} offset={100} once={true}>
				<WowCVSection />
			</LazyLoad>
			<LazyLoad height={80} offset={100} once={true}>
				<PodcastAudio data={resultAll?.podcastData} />
			</LazyLoad>
			<LazyLoad height={452} offset={100} once={true}>
				<HRInsider />
			</LazyLoad>
		</div>
	)
}

export default memo(HomePage)
