import { ELanguage, SlideShow } from '@microfrontends/react-components'
import React, { memo } from 'react'
import { trackingUTM } from '../../../helpers/utmTracking'
import { getCompanySpotlight } from '../apiClient'

import { TCompanySpotLight } from '../../../services/type'
import AppContext from '../../../context/AppContext'
import Image from 'next/legacy/image'
import dynamic from 'next/dynamic'

const SectionBlock = dynamic(() => import('./../../sectionBlock'))

const isMobile = function () {
	return typeof window !== 'undefined' ? window.innerWidth < 769 : false
}

interface IProps {
	company?: TCompanySpotLight[]
	langCode: ELanguage
	isBot: boolean
}
interface IState {
	banners?: TCompanySpotLight[]
	forceHide: boolean
}
class CompanySpotlight extends React.Component<IProps, IState> {
	static contextType = AppContext
	declare context: React.ContextType<typeof AppContext>

	constructor(props: IProps) {
		super(props)

		this.state = {
			banners: this.props.company || [],
			forceHide: false
		}
	}

	populateData = async () => {
		const { banners } = await getCompanySpotlight(this.props.langCode)
		if (banners.length > 0) {
			this.setState({
				banners: banners
			})
			return
		}
		this.setState({
			forceHide: true
		})
	}

	async componentDidMount() {
		if (this.state?.banners && this.state?.banners?.length > 0) {
			return
		}
		await this.populateData()
	}

	renderSlideContents() {
		const { banners } = this.state
		const { t: trans } = this.context

		if (banners?.length === 0) {
			return null
		}
		return banners?.map((banner, index) => {
			const ariaLabel =
				trans('Latest ') +
				`${banner.title} ` +
				trans(
					'employment/hiring with high salary & attractive benefits'
				)

			return (
				<div className='swiper-slide' key={index}>
					<div className='spotlightBlock'>
						<div
							className='spotlightBlock__background'
							role='img'
							aria-label={ariaLabel}
							style={{
								backgroundImage: `url('${banner.backgroundImage}')`
							}}
						/>
						<div className='spotlightBlock__wrapper'>
							<div className='spotlightBlock__inner'>
								{isMobile() && (
									<a
										className='overlay-link'
										href={banner.url}
										rel='noreferrer'
										onClick={trackingUTM}
									></a>
								)}
								<div className='spotlightBlock__logo'>
									<a
										href={banner.url}
										rel='noreferrer'
										onClick={trackingUTM}
									>
										<Image
											src={banner.logo}
											width={200}
											height={200}
											alt={
												trans('Latest ') +
												`${banner.title} ` +
												trans(
													'employment/hiring with high salary & attractive benefits'
												)
											}
										/>
									</a>
								</div>

								<div className='spotlightBlock__content-wrapper'>
									<div className='spotlightBlock__title'>
										<a
											href={banner.url}
											rel='noreferrer'
											onClick={trackingUTM}
										>
											<h3>{banner.title}</h3>
										</a>
									</div>
									<div className='spotlightBlock__message'>
										{banner.message}
									</div>
									<div className='spotlightBlock__content'>
										<p className='spotlightBlock__mainContent'>
											{banner.description}...
											<a
												href={banner.url}
												rel='noreferrer'
												onClick={trackingUTM}
											>
												{
													this.context.t(
														'View more'
													) as string
												}
											</a>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		})
	}

	render() {
		const { forceHide } = this.state

		if (forceHide) return null

		let sectionClass: string[] = [
			'sectionBlock_has-outside-padding',
			'sectionBlock_company-spotlight',
			'animated fadeIn take-1-second'
		]

		return (
			<SectionBlock
				title={this.context.t('Company Spotlight') as string}
				sectionClass={sectionClass}
				container={true}
			>
				<div className='swiper-container'>
					{isMobile() ? (
						<SlideShow
							id={`company-spotlight`}
							pagination={{
								maxShow: 4,
								type: 'defaultNotArrow',
								extraPage: 1
							}}
							container={{ style: { width: '100%' } }}
							autoEnable
							transitionTime={500}
							isRobot={this.props.isBot}
						>
							{this.renderSlideContents()}
						</SlideShow>
					) : (
						<SlideShow
							id={`company-spotlight-mobile`}
							pagination={{ maxShow: 4, extraPage: 1 }}
							container={{ style: { width: '100%' } }}
							autoEnable
							autoTime={7000}
							transitionTime={500}
							isRobot={this.props.isBot}
						>
							{this.renderSlideContents()}
						</SlideShow>
					)}
				</div>
			</SectionBlock>
		)
	}
}

export default memo(CompanySpotlight)
