import promotionService, {
	defaultFilters,
	TFiltersInfo,
	TPromotionConfig
} from './PromotionService'
import SectionBlock from '../sectionBlock'
import React, { memo, useContext, useEffect, useState } from 'react'
import withSlidableJobList from '../HOCs/withSlidableJobList'
import { ELanguage, TUser } from '@microfrontends/app-shell-v2'
import AppContext from '../../context/AppContext'
import { Utils } from '@microfrontends/react-components'
import { buildVNWUrlFromPath } from '../../helpers/urlBuilder'
import { isMobile } from '../../helpers'
import { TJobBlock } from '../JobBlock/type'
import dynamic from 'next/dynamic'

const JobBlock = dynamic(() => import('../JobBlock'))

const PromotionJobs = memo(
	withSlidableJobList<TJobBlock>(
		JobBlock,
		(props: {
			filterParams: TFiltersInfo | null
			langCode: ELanguage
			user: TUser
		}) =>
			promotionService.getListJobs(
				props.filterParams,
				props.langCode,
				props.user
			),
		false,
		'',
		true,
		(props, prevProps) =>
			JSON.stringify(props.filterParams) !==
			JSON.stringify(prevProps.filterParams)
	)
)

function PromotionJobsComponent() {
	const { t, user, langCode } = useContext(AppContext)
	const [promotionConfig, setPromotionConfig] = useState<TPromotionConfig>({
		nameEn: '',
		nameVi: '',
		jobFilter: defaultFilters
	})
	const [forceHide, setForceHide] = useState(false)

	useEffect(() => {
		;(async () => {
			const configData = await promotionService.getFiltersFromNaviCMS()
			if (configData) {
				setPromotionConfig(configData)
			}
		})()
	}, [])

	const promotionTitle =
		langCode === ELanguage.EN
			? promotionConfig.nameEn
			: promotionConfig.nameVi
	const { jobFilter } = promotionConfig

	const searchAllPath = buildVNWUrlFromPath(
		Utils.buildSearchUrl(
			{
				jobLevelId: parseInt(jobFilter.jobLevel) || 0,
				typeWorkingId: parseInt(jobFilter.typeWorking) || 0,
				jobFunctionId: [
					{
						parentId: parseInt(jobFilter.groupJobFunction),
						childrenIds: [-1]
					}
				]
			},
			langCode
		)
	)

	const isEmptyFilter = Object.values(promotionConfig.jobFilter).every(
		(val) => val === ''
	)
	if (isEmptyFilter) return null
	return (
		<SectionBlock
			sectionClass={forceHide ? ['is-hidden'] : []}
			containerClass={['promotion-jobs']}
			title={
				<span className='has-text-weight-normal'>
					{t('Best Jobs')}&nbsp;
					{isMobile() ? (
						<a href={`${searchAllPath}`} title={t('View All')}>
							<strong>{promotionTitle}</strong>
						</a>
					) : (
						<strong>{promotionTitle}</strong>
					)}
				</span>
			}
			ctaLink={
				<a
					href={`${searchAllPath}`}
					className='is-hidden-mobile'
					title={t('View All')}
				>
					{t('View All')}
				</a>
			}
		>
			<PromotionJobs
				user={user}
				filterParams={promotionConfig.jobFilter}
				langCode={langCode}
				onDataSourceComplete={(jobs: any) =>
					setForceHide(jobs.length < 9)
				}
			/>
		</SectionBlock>
	)
}

export default memo(PromotionJobsComponent)
