import React, { memo } from 'react'
import AppContext from '../../context/AppContext'
import { utmHrInsider } from '../../helpers/utmTracking'
import CardBlock from './cardBlock'
import SectionBlock from './../sectionBlock'
import getArticle from './dataSource'
import { TArticleHrInsider } from '../../services/type'

const isMobile = typeof window !== 'undefined' ? window.innerWidth < 768 : false

interface IProps {
	articles?: TArticleHrInsider[]
}

interface IState {
	hrArticles: TArticleHrInsider[]
	forceHide?: boolean
}

class HRInsider extends React.Component<IProps, IState> {
	slider: any
	static contextType = AppContext
	declare context: React.ContextType<typeof AppContext>
	VNW_URL: string = 'https://www.vietnamworks.com'

	constructor(props: IProps) {
		super(props)

		this.state = {
			hrArticles: this.props.articles || [],
			forceHide: false
		}

		this.slider = React.createRef()
	}

	async componentDidMount() {
		if (this.state.hrArticles.length > 0) {
			return
		}

		const articles = await getArticle()
		if (articles.length > 0) {
			this.setState({
				hrArticles: articles
			})
			return
		}
		this.setState({
			forceHide: true
		})
	}

	buildLoading() {
		return [
			<div key='0'>
				<div className='placeholder-shimmer placeholder placeholder_type-article' />
			</div>,
			<div key='1'>
				<div className='placeholder-shimmer placeholder placeholder_type-article' />
			</div>,
			<div key='2'>
				<div className='placeholder-shimmer placeholder placeholder_type-article' />
			</div>,
			<div key='3'>
				<div className='placeholder-shimmer placeholder placeholder_type-article' />
			</div>
		]
	}

	render() {
		const { forceHide } = this.state
		const { t } = this.context

		let slideContent: any = null
		if (this.state.hrArticles.length > 0) {
			slideContent = this.state.hrArticles.map((item, index) => {
				return (
					<article key={index}>
						<CardBlock
							image={item.image}
							title={item.title}
							category={item.category}
							content={item.description}
							url={`${this.VNW_URL}${item.url}?${utmHrInsider()}`}
						/>
					</article>
				)
			})
		} else {
			slideContent = this.buildLoading()
		}

		let sectionClass = ['hr-insider']
		if (forceHide || !slideContent) {
			sectionClass.push('is-hidden')
		}

		const viewAllLabel = t('View All') as string

		const title = isMobile ? (
			<span>
				{t('Career Advice from')}{' '}
				<a
					href={process.env.HR_INSIDER_URL || ''}
					title={viewAllLabel}
					rel='noreferrer'
				>
					HR Insider
				</a>
			</span>
		) : (
			t('Career Advice from HR Insider')
		)

		return (
			<SectionBlock
				title={title}
				sectionClass={sectionClass}
				ctaLink={
					<a
						href={process.env.HR_INSIDER_URL || ''}
						title={viewAllLabel}
						className='is-hidden-mobile'
						rel='noreferrer'
					>
						{viewAllLabel}
					</a>
				}
			>
				<div className='article-wrapper'>{slideContent}</div>
			</SectionBlock>
		)
	}
}

export default memo(HRInsider)
