import { ELanguage } from '@microfrontends/react-components'
import { utmPromotionJob } from '../../helpers/utmTracking'
import { TUser } from '@microfrontends/app-shell-v2'

const apiFiltersUrl = `${process.env.API_BANNER_BASE_URL}${'/v2.0'}/promotion`
const apiSearchJobUrl = `${process.env.API_DOMAIN}/job-search/v1.0/search`
const baseApiUrl = process.env.API_DOMAIN

export type TFiltersInfo = {
	benefit: string
	industry: string
	jobLevel: string
	salaryMin: string
	salaryMax: string
	typeWorking: string
	groupJobFunction: string
}

export type TListJobs = {
	title: string
	locations: any
	company: string
	logo: string
	url: string
	isHot: boolean
	jobId: number
	salary: string
	jobLevel: string
	address: string
	jobDescription: string
	jobRequirement: string
}

export const defaultFilters: TFiltersInfo = {
	benefit: '',
	industry: '',
	jobLevel: '',
	salaryMin: '',
	salaryMax: '',
	typeWorking: '',
	groupJobFunction: ''
}

export type TPromotionConfig = {
	nameVi: string
	nameEn: string
	jobFilter: TFiltersInfo
}

class PromotionService {
	private _handleFilters(filters: any): TFiltersInfo {
		const noBracketRegex = /(\[|\])/g

		const parsedFilters = Object.entries(filters).reduce(
			(total, current: any) => {
				return Object.assign({}, total, {
					[current[0]]:
						JSON.stringify(current[1])?.replace(
							noBracketRegex,
							''
						) || ''
				})
			},
			defaultFilters
		)
		return parsedFilters
	}

	async getFiltersFromNaviCMS(): Promise<TPromotionConfig | null> {
		const result = await fetch(apiFiltersUrl).then((res) => res.json())

		if (result && result.data) {
			const filters: TFiltersInfo = {
				benefit: result.data?.attributes?.benefit,
				industry: result.data?.attributes?.industry,
				jobLevel: result.data?.attributes?.jobLevel,
				salaryMin: result.data?.attributes?.minSalary,
				salaryMax: result.data?.attributes?.maxSalary,
				typeWorking: result.data?.attributes?.typeWorking,
				groupJobFunction: result.data?.attributes?.groupJobFunction
			}

			return {
				nameEn: result.data?.attributes?.campaignEN || '',
				nameVi: result.data?.attributes?.campaignVI || '',
				jobFilter: this._handleFilters(filters)
			}
		}

		return null
	}

	async getListJobs(
		filters: TFiltersInfo | null,
		langCode: ELanguage,
		user: TUser
	): Promise<TListJobs[] | []> {
		if (!filters) return []
		const listFilter: { field: string; value: string }[] = []
		const ranges: { field: string; operator: string; value: string }[] = []

		if (filters.groupJobFunction !== '') {
			const filterValue: {
				parentId: number
				childrenIds: number[]
			}[] = []
			filters.groupJobFunction.split(',').forEach((group) => {
				filterValue.push({
					parentId: parseInt(group),
					childrenIds: [-1]
				})
			})
			listFilter.push({
				field: 'jobFunction',
				value: JSON.stringify(filterValue)
			})
		}

		if (filters.benefit !== '') {
			listFilter.push({
				field: 'benefits.benefitId',
				value: filters.benefit
			})
		}

		if (filters.jobLevel !== '') {
			listFilter.push({
				field: 'jobLevelId',
				value: filters.jobLevel
			})
		}

		if (filters.typeWorking !== '') {
			listFilter.push({
				field: 'typeWorkingId',
				value: filters.typeWorking
			})
		}

		if (parseInt(filters.salaryMin) < parseInt(filters.salaryMax)) {
			if (filters.salaryMin !== '') {
				ranges.push({
					field: 'salary',
					operator: '>=',
					value: filters.salaryMin
				})
			}

			if (filters.salaryMax !== '') {
				ranges.push({
					field: 'salary',
					operator: '<=',
					value: filters.salaryMax
				})
			}
		}

		const listOrder = [
			{
				field: 'isSalaryVisible',
				value: 'desc'
			},
			{
				field: 'salary',
				value: 'desc'
			}
		]

		const listJobs = await fetch(apiSearchJobUrl, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				'Accept-Language': langCode
			},
			body: JSON.stringify({
				filter: listFilter,
				order: listOrder,
				ranges: ranges,
				userId: user ? user.userId : 0,
				hitsPerPage: 90
			})
		}).then((response) => response.json())

		if (listJobs && listJobs.meta?.code === 200) {
			const parsedListJobs: TListJobs[] = listJobs.data.map(
				(item: any) => {
					const locations = item?.workingLocations?.map(
						(location: { cityName: string; cityNameVI: string }) =>
							langCode === ELanguage.EN
								? location.cityName
								: location.cityNameVI
					)
					const customUrl = `${process.env.VNW_BASE_URL}/${
						item.alias
					}-${item.jobId}-${
						langCode === ELanguage.EN ? 'jd' : 'jv'
					}/?${utmPromotionJob()}`

					return {
						title: item.jobTitle,
						locations: locations,
						companyId: item.companyId,
						company: item.isAnonymous
							? "Vietnamworks' Client"
							: item.companyName,
						logo: item.companyLogo,
						url: customUrl,
						isHot: false,
						jobId: item.jobId,
						salary: item.prettySalary,
						jobLevel:
							langCode === ELanguage.VI
								? item.jobLevelVI
								: item.jobLevel,
						jobLevelId: item.jobLevelId,
						address: item.address,
						jobDescription: item.jobDescription,
						jobRequirement: item.jobRequirement,
						isAnonymous: item.isAnonymous
					}
				}
			)

			if (parsedListJobs.length > 0 && user?.accessToken) {
				const jobIds = parsedListJobs.map((item) => item.jobId)
				const resSpecialJobs = await fetch(
					`${baseApiUrl}/api-gateway/v1.0/special-jobs`,
					{
						method: 'POST',
						mode: 'cors',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${user?.accessToken}`
						},
						body: JSON.stringify({
							jobId: jobIds
						})
					}
				)

				const specialJobs = await resSpecialJobs.json()
				const formatJobs = parsedListJobs.map((item, idx) => {
					return {
						...item,
						...(specialJobs?.data?.[idx]?.attributes || '')
					}
				})

				return formatJobs
			} else {
				return parsedListJobs
			}
		}

		return []
	}
}

const promotionService = new PromotionService()
export default promotionService
