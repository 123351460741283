import React, { memo } from 'react'

import { getJobCorner } from '../apiClient'
import withIntegratedAPI from '../HOCs/withIntegratedAPI'

import dynamic from 'next/dynamic'
import { TJobCorner } from './type'

const JobCorner = dynamic(() => import('./jobCorner'))

const ContentLoader = dynamic(
	async () => (await import('../../ContentLoader')).ContentLoader
)
const IntegratedJobCorner = withIntegratedAPI<Partial<TJobCorner>>(
	JobCorner,
	getJobCorner,
	<ContentLoader height={502} width={'100%'} />,
	['job-corner animated fadeIn take-1-second']
)

export default memo(IntegratedJobCorner)
