import { makeRequest } from '../../services/apiClient'
import { utmBestJobInCategory } from '../../helpers/utmTracking'
import { TJobFunctionItem } from '.'
import { ELanguage } from '@microfrontends/react-components'
import { TUser } from '@microfrontends/app-shell-v2'

const baseApiUrl = process.env.API_DOMAIN
const apiUrl = `${process.env.API_DOMAIN}/job-search/v1.0/search`

export default async function dataSource(
	jobFunctionItem: TJobFunctionItem,
	user: TUser,
	langCode: ELanguage
) {
	let data = []

	try {
		const params = {
			filter: [
				{
					field: 'jobFunction',
					value: JSON.stringify([
						{
							parentId: jobFunctionItem.parentId,
							childrenIds: [-1]
						}
					])
				}
			],
			order: [
				{ field: 'isSalaryVisible', value: 'desc' },
				{ field: 'salary', value: 'desc' }
			],
			userId: user ? user.userId : 0,
			hitsPerPage: 90
		}
		const resData = await makeRequest(
			apiUrl,
			'post',
			JSON.stringify(params),
			langCode
		)

		data = resData?.data || []
	} catch (err) {
		console.log(err)
	}

	if (data.length > 0) {
		const jobs = data.map((item: any) => {
			const locations = item.workingLocations.map(
				(location: { cityName: string; cityNameVI: string }) =>
					langCode === ELanguage.EN
						? location.cityName
						: location.cityNameVI
			)
			const customUrl = `${process.env.VNW_BASE_URL}/${item.alias}-${
				item.jobId
			}-${
				langCode === ELanguage.EN ? 'jd' : 'jv'
			}/?${utmBestJobInCategory(jobFunctionItem.parentId)}`
			return {
				title: item.jobTitle,
				locations: locations,
				company: item.isAnonymous
					? "Vietnamworks' Client"
					: item.companyName,
				logo: item.companyLogo,
				url: customUrl,
				isHot: false,
				jobId: item.jobId,
				companyId: item.companyId,
				salary: item.prettySalary,
				jobLevel:
					langCode === ELanguage.VI ? item.jobLevelVI : item.jobLevel,
				jobLevelId: item.jobLevelId,
				address: item.address,
				jobDescription: item.jobDescription,
				jobRequirement: item.jobRequirement,
				isAnonymous: item.isAnonymous
			}
		})

		if (jobs.length > 0 && user?.accessToken) {
			const jobIds = jobs.map((item: { jobId: number }) => item.jobId)

			const resSpecialJobs = await fetch(
				`${baseApiUrl}/api-gateway/v1.0/special-jobs`,
				{
					method: 'POST',
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${user?.accessToken}`
					},
					body: JSON.stringify({
						jobId: jobIds
					})
				}
			)

			const specialJobs = await resSpecialJobs.json()
			const formatJobs = jobs.map((item: any, idx: number) => {
				return {
					...item,
					...specialJobs.data[idx].attributes
				}
			})

			return formatJobs
		} else {
			return jobs
		}
	}

	return []
}
