import React, { memo } from 'react'
import { getHotCategories } from '../apiClient'
import SectionBlock from './../../sectionBlock'
import { Slider } from '@microfrontends/react-components'
import { ELanguage } from '@microfrontends/app-shell-v2'
import { LANG_CODE_VI } from '../../../helpers/language'
import { isMobile } from '../../../helpers'
import AppContext from '../../../context/AppContext'
import { TCategries } from '../../../services/type'
import Image from 'next/legacy/image'

type THotCategoriesStates = {
	categories?: TCategries[]
	forceHide: boolean
	langVi: boolean
}

interface THotCategoriesProps {
	categories?: TCategries[]
	langCode: ELanguage
	isRobot: boolean
}
class HotCategories extends React.Component<
	THotCategoriesProps,
	THotCategoriesStates
> {
	static contextType = AppContext
	declare context: React.ContextType<typeof AppContext>

	constructor(props: THotCategoriesProps) {
		super(props)
		this.state = {
			categories: this.props?.categories || [],
			forceHide: false,
			langVi: this.props?.langCode === ELanguage.VI ? true : false
		}
	}

	async componentDidMount() {
		const langCode = this.props?.langCode || LANG_CODE_VI

		if (this.state.categories?.length === 0) {
			const data = await getHotCategories(langCode)
			if (data.categories.length > 0) {
				this.setState({
					categories: data.categories
				})
				return
			}
			this.setState({
				forceHide: true
			})
		}
	}

	addSpaceSlash = (name: string) => {
		return name?.replace('/', ' / ')
	}

	render() {
		const { forceHide } = this.state

		let categoryList = this.state.categories?.map((category, index) => {
			const mappedCategory = {
				category_icon: category.icon,
				category_name_vn: category.groupJobFunctionNameVI,
				category_name: category.groupJobFunctionName,
				category_url: category.url
			}
			const prefix = '&'
			const categoryLink =
				mappedCategory.category_url +
				prefix +
				'utm_source_navi=vnw_homepage&utm_medium_navi=HotCategories&ignoreLocation=true'
			return (
				<div key={'category-idx-' + index} className='wrap-item'>
					<div key={index} className='category-item'>
						<a href={categoryLink}>
							<Image
								src={mappedCategory.category_icon}
								alt='category icon'
								width={90}
								height={90}
							/>
							<div className='wrap-name'>
								<h3 className='title truncate-text-2-line'>
									{this.state.langVi
										? this.addSpaceSlash(
												mappedCategory.category_name_vn
										  )
										: this.addSpaceSlash(
												mappedCategory.category_name
										  )}
								</h3>
							</div>
							<p className='total'>
								{category.total}{' '}
								<span>{this.context?.t('jobs') as string}</span>
							</p>
						</a>
					</div>
				</div>
			)
		})

		let sectionClass = ['sectionBlock_hot-categories']
		if (forceHide) {
			sectionClass.push('is-hidden')
		}

		return (
			<SectionBlock
				sectionClass={sectionClass}
				title={this.context?.t('Hot Categories') as string}
			>
				{isMobile() ? (
					<div className='mobile-div-fk-slide'>{categoryList}</div>
				) : (
					<Slider
						id='hot-cagories'
						slides={{ elementPerView: 5 }}
						pagination={{
							type: 'whiteArrowOnly',
							elementPerPage: 3
						}}
					>
						{categoryList}
					</Slider>
				)}
			</SectionBlock>
		)
	}
}

export default memo(HotCategories)
