import { EAppMode, Podcast } from '@microfrontends/react-components'
import React, { useState, useEffect, useContext, memo } from 'react'
import getPodcastData, { IPodcast } from './dataSource'
import SectionBlock from '../sectionBlock'
import AppContext from '../../context/AppContext'

interface IProps {
	data?: IPodcast
}

const PodcastAudio = ({ data }: IProps) => {
	const { langCode } = useContext(AppContext)
	const mode = process.env.APP_SHELL_MODE as EAppMode
	const [podcastData, setPodcastData] = useState<IPodcast | undefined>(data)

	useEffect(() => {
		;(async () => {
			if (data) {
				setPodcastData(data)
				return
			}

			const podcastData = await getPodcastData(langCode)
			setPodcastData(podcastData)
		})()
	}, [data, langCode])

	return (
		<SectionBlock>
			<Podcast
				language={langCode}
				appMode={mode}
				podcastData={podcastData}
			/>
		</SectionBlock>
	)
}

export default memo(PodcastAudio)
