import localforage from 'localforage'
import { getCurrentTimestamp } from './../../helpers/helper'

localforage.config({
	name: 'homepageCache',
	version: 1,
	storeName: 'cache'
});

export async function getFromCache(key, checkVersion) {
	try {
		const { data, expire, version } = await localforage.getItem(key);

		if (!version || checkVersion !== version) {
			return []
		}

		if (expire >= getCurrentTimestamp()) {
			return data
		}
	} catch (err) {
		return []
	}

	return []
}

export async function putToCache(key, data, expireInSec, version) {
	const cacheData = {
		data: data,
		expire: getCurrentTimestamp() + expireInSec,
		version: version
	}

	return await localforage.setItem(key, cacheData)
}

export function hashCode(text) {
    var hash = 0;
    if (text.length == 0) {
        return hash;
    }
    for (var i = 0; i < text.length; i++) {
        var char = text.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}