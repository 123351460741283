import { stripTags } from '../../helpers/string'
import { TArticleHrInsider } from '../../services/type'
import { HR_INSIDER_CACHE_KEY } from '../../ssr/const'
import { hashCode, putToCache } from './../../services/cache'

const API_PATH = process.env.HR_INSIDER_API
const HR_INSIDER_CACHE_TIME = process.env.HR_INSIDER_EXPIRATION_CACHE
	? parseInt(process.env.HR_INSIDER_EXPIRATION_CACHE)
	: 0
const CACHE_VERSION = hashCode(API_PATH)

export default async function dataSource(limit: number | undefined = 4) {
	let articles: TArticleHrInsider[] = []
	if (!!articles && articles.length == 0) {
		try {
			const url = `${process.env.API_DOMAIN}/navi-api/api/blogs?limit=${limit}`

			const response = await fetch(url)
			let data = await response.json()

			if (Array.isArray(data) && data.length > limit) {
				data = data.slice(0, limit)
			}

			articles = data.map((item: any) => {
				const image = item.cover_picture
				const tags = item.tags || []
				const category = tags.map((item: any) => item.name).join(', ')

				return {
					id: item.id,
					title: stripTags(item.title),
					image: image,
					description: stripTags(item.short_description),
					url: item.hrDetailSlug,
					category: category
				}
			})
			await putToCache(
				HR_INSIDER_CACHE_KEY,
				articles,
				HR_INSIDER_CACHE_TIME,
				CACHE_VERSION
			)
		} catch (err) {
			console.error(err)
		}
	}

	return articles
}
