import React, { memo, useContext, useState } from 'react'
import AppContext from '../../context/AppContext'
import { getIdFromCode } from '../../helpers/language'
import { buildSearchByJobFunctionUrl } from '../../helpers/urlBuilder'
import { ELanguage, TNestedGenericId } from '@microfrontends/react-components'

import withSlidableJobPopulation from '../HOCs/withSlidableJobList'

import dataSource from './dataSource'
import SectionBlock from '../sectionBlock'
import { TUser, useInitialData } from '@microfrontends/app-shell-v2'
import dynamic from 'next/dynamic'

const JobBlock = dynamic(() => import('../JobBlock'))

export type TJobFunctionItem = {
	parentId: number
	childrenIds: number[]
}

type TProps = {
	jobFunctionItem: TNestedGenericId
	locationId: number
	langCode: ELanguage
	user: any
}

function BestJobsInJobFunction(props: TProps) {
	const { jobFunctionItem, locationId, langCode } = props
	const initDataProvider = useInitialData(langCode)
	const jobsFnc = initDataProvider.jobFunctions
	const jobFunctionOption = jobsFnc?.parent?.find(
		(jobFunction) => jobFunction.id === jobFunctionItem.parentId
	) || {
		name: '',
		nameEn: ''
	}

	const { t, user } = useContext(AppContext)

	const [forceHide, setForceHide] = useState(false)

	const jobFunctionName =
		langCode === ELanguage.EN
			? jobFunctionOption.nameEn
			: jobFunctionOption.name

	const searchUrl = buildSearchByJobFunctionUrl(
		[{ parentId: jobFunctionItem.parentId, childrenIds: [-1] }],
		getIdFromCode(langCode)
	)

	const BestCategoryJobs = withSlidableJobPopulation(
		JobBlock,
		(props: { jobFunctionItem: TJobFunctionItem; langCode: ELanguage }) =>
			dataSource(props.jobFunctionItem, user as TUser, props.langCode),
		false,
		'section-best-job-function-jobs',
		true
	)
	return (
		<SectionBlock
			sectionClass={[
				'sectionBlock_job-list',
				forceHide ? 'is-hidden' : ''
			]}
			title={
				<>
					<span className='has-text-weight-normal'>
						{t('Best Jobs In')}
					</span>
					<a className='is-hidden-tablet' href={searchUrl}>
						&nbsp;{jobFunctionName}
					</a>
					<span className='is-hidden-mobile'>
						&nbsp;{jobFunctionName}
					</span>
				</>
			}
			ctaLink={
				<a
					className='sectionBlock__link is-hidden-mobile'
					href={searchUrl}
					title={t('View All')}
				>
					{t('View All')}
				</a>
			}
		>
			<BestCategoryJobs
				jobFunctionItem={jobFunctionItem}
				locationId={locationId}
				langCode={langCode}
				onDataSourceComplete={(jobs: any[]) =>
					setForceHide(jobs.length < 9)
				}
				user={user}
			/>
		</SectionBlock>
	)
}

export default memo(BestJobsInJobFunction)
