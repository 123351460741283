import React from 'react'
import SectionBlock from '../../sectionBlock'
import { LANG_CODE_VI } from '../../../helpers/language'

function withIntegratedAPI<T>(
	WrappedComponent: React.ComponentType<T>,
	dataSource: Function,
	LoaderComponent: JSX.Element | null,
	sectionClass: string[] = []
) {
	return class withIntegratedAPI extends React.Component<any, any> {
		constructor(props: T) {
			super(props)

			this.state = {
				data: this.props.jobCorner || null,
				forceHide: LoaderComponent ? false : true // if has loader, still show loader, if not, hide as default
			}
		}

		async componentDidMount() {
			try {
				if (this.state.data !== null) {
					this.setState({
						...this.state,
						forceHide: false
					})
					return
				}
				const langCode = this.props.langCode || LANG_CODE_VI
				const data = await dataSource(langCode)
				if (data !== null || data?.length > 0) {
					this.setState({
						data: data,
						forceHide: false
					})
				} else {
					this.handleError('No data found!')
				}
			} catch (err) {
				this.handleError(err)
			}
		}

		handleError(err: any) {
			this.setState({
				forceHide: true
			})
			console.error('handleError ~ err:', err)
		}

		render() {
			const customSectionClass = [
				...sectionClass,
				this.state.forceHide ? 'is-hidden' : ''
			]
			return (
				<SectionBlock sectionClass={customSectionClass}>
					{this.state.data ? (
						<WrappedComponent {...this.state.data} />
					) : (
						LoaderComponent
					)}
				</SectionBlock>
			)
		}
	}
}

export default withIntegratedAPI
